import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/core";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Layout } from "@components";
import Section from "@components/Section";
import SectionTitle from "@components/SectionTitle";
import Wrapper from "@components/Wrapper";
import useOnScreen from "@src/hooks/useOnScreen";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { withPreview } from "gatsby-source-prismic";
import { RichText as PrismicRichText } from "prismic-dom";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import linkResolver from "../linkResolver";

const { Elements } = PrismicRichText;

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

const sectionSerializer = (type, element, content, children, key) => {
  let props = {};

  switch (type) {
    case Elements.paragraph:
      props = {
        as: "p",
        mb: 4,
        fontSize: "md",
      };
      return React.createElement(
        Text,
        propsWithUniqueKey(props, key),
        children
      );
    default:
  }
};

const Wave = () => {
  return (
    <Box
      position="absolute"
      width="100%"
      height="150px"
      transform="translateY(-100%)"
    >
      <Box
        as="svg"
        width="100%"
        height="100%"
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
      >
        <path
          d="M0.00,49.98 C137.69,109.03 346.50,-15.28 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: "none", fill: "white" }}
        ></path>
      </Box>
    </Box>
  );
};
const WaveBottom = () => {
  return (
    <Box
      position="absolute"
      width="100%"
      height="150px"
      transform="translateY(-50px) scaleY(-1)"
    >
      <Box
        as="svg"
        width="100%"
        height="100%"
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
      >
        <path
          d="M0.00,49.98 C149.99,150.00 249.72,34.03 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: "none", fill: "white" }}
        ></path>
      </Box>
    </Box>
  );
};

const useHasBeenOnScreen = (ref) => {
  const [hasBeenOnScreen, setHasBeenOnScreen] = useState(false);
  const onScreen = useOnScreen(ref, "-300px");

  useEffect(() => {
    if (!hasBeenOnScreen && onScreen) {
      setHasBeenOnScreen(true);
    }
  }, [onScreen]);

  return [onScreen, hasBeenOnScreen];
};

const About = (props) => {
  const homepage = props.data.prismicHomepage;

  return (
    <Section dark py={"8rem"} pb={"8em"}>
      <Wrapper mx="auto" width="100%">
        <Flex
          borderRadius="md"
          mx={[1, 1, 2, 2, -12]}
          bg="white"
          boxShadow="lg"
          py={10}
          pb={8}
          px={10}
          flexDirection={[
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "row",
          ]}
        >
          <Box
            boxShadow="md"
            borderRadius="md"
            flex="1"
            mr={[0, 0, 0, "3rem"]}
            ml={[0, 0, 0, "-5rem"]}
            alignSelf="start"
            // my={'auto'}
            // my={[0, 0, 0, "-5rem"]}
            width="100%"
            height="100%"
            my="auto"
          >
            {/* <Grid
                width="100%"
                alignItems="center"
                justifyContent="center"
                flex="1"
              > */}
            <Box
              boxShadow="0 -1px 19px rgba(0,0,0,.2)"
              borderRadius="md"
              overflow="hidden"
            >
              <Img fluid={props.data.portrait.childImageSharp.fluid} />
            </Box>
            {/* </Grid> */}
          </Box>
          <Box flex="1">
            <SectionTitle textAlign="left" mb={6} variant="script">
              {homepage.data.about_title}
            </SectionTitle>
            <RichText
              render={homepage.data.about_me_content.raw}
              linkResolver={linkResolver}
              htmlSerializer={sectionSerializer}
            />
            {/* <Text fontSize="md" color="black" mb={6}>
                
                Jag heter Kerstin Sandqvist och är auktoriserad socionom och
                KBT-terapeut. Min grundsyn är systemteoretisk och salutogen.
                Systemteorin ser allt mänskligt handlande och mänskliga problem
                som något som sker och uppstår i ett samspel mellan människor.
                Vi blir således till i våra relationer. I det salutogena
                förhållningssättet har jag fokus på det som är friskt och
                fungerar.
              </Text>
              <Text fontSize="md" color="gray.900" mb={6}>
                Jag har under mitt arbete inom socialtjänsten och sjukvården en
                lång erfarenhet av att samtala med människor i svåra
                livssituationer.
              </Text> */}
            {/* <Link color="blue.300" fontWeight="bold" mt={10}>
                  Läs mer om mig
                </Link> */}
          </Box>
        </Flex>
      </Wrapper>
    </Section>
  );
};

const IndexPage = (props) => {
  const homepage = props.data.prismicHomepage;

  const iconRef = useRef();
  const [onScreen, hasBeenOnScreen] = useHasBeenOnScreen(iconRef);

  return (
    <Layout>
      <Flex
        color="black"
        borderBottom="1px solid whitesmoke"
        bgImage="url(https://images.unsplash.com/photo-1528460033278-a6ba57020470?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1575&q=80)"
        // bgImage=" url('https://www.ignytebrands.com/wp-content/uploads/brand-architecture-creating-clarity-from-chaos-branding-ignyte-1b.jpg')"
        // bgColor="blue.300"
        bgSize="cover"
        bgPos="bottom"
        minHeight={[0, 0, "60vh"]}
      >
        <Wrapper
          // bg={["rgba(0,0,0,.25)", "rgba(0,0,0,.25)", "transparent"]}

          textAlign={["center", "center", "center", "left"]}
          justifyContent={["center", "center", "center", "left"]}
          pt={["8em", "6em", "4em", "6em"]}
          pb={{ md: "calc(4em + 180px)", sm: "180px" }}
          mb={0}
          // minHeight="70vh"
          color="black"
          flex="1"
          width="100%"
          mt={0}
          px={["2em", "2em", "2em", 0]}
        >
          <Flex flexDir="column" mt="auto" mx={"auto"} pb={["220px", 0]}>
            <Flex flexDir="column" mb={4}>
              <Heading
                as="h2"
                size="lg"
                mb={[2, 2, 1]}
                mt={[4, 4, 0]}
                variant="script"
              >
                {homepage.data.title.text}
              </Heading>
              <Heading as="h1" size="xl" mb={2}>
                KBT-Terapi i Stockholm
              </Heading>
              <Flex
                maxW={["none", "none", "none", "70%"]}
                mx={["auto", "auto", "auto", 0]}
                style={{}}
              >
                <RichText
                  render={homepage.data.content.raw}
                  linkResolver={linkResolver}
                  // htmlSerializer={htmlSerializer}
                />
              </Flex>
              {/* <Text
                  dangerouslySetInnerHTML={{
                    __html: homepage.data.content.html,
                  }}
                  maxW={["none", "none", "60vw"]}
                />
                <RichText */}
            </Flex>
            <Flex
              alignItems="center"
              flexDirection={["column", "row", "row"]}
              margin={["auto", "auto", "auto", 0]}
            >
              <Link
                href="/kontakt"
                fontSize="sm"
                variant="unstyled"
                color="black"
                textDecor="none"
                _hover={{
                  textDecor: "none",
                }}
              >
                <Button
                  colorScheme="white"
                  aria-label="Kontakt"
                  variant="outline"
                  size="sm"
                >
                  Kontakt
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Wrapper>
      </Flex>
      <Wave />
      <Section>
        <Wrapper mx="auto" width="100%">
          <Flex
            flexDirection={[
              "column-reverse",
              "column-reverse",
              "column-reverse",
              "row",
            ]}
          >
            <Grid
              width="100%"
              // alignItems="center"

              flex="1"
              display={["none", "none", "none", "grid"]}
              ref={iconRef}
            >
              <Box
                p="20px"
                mr={"10px"}
                display="block"
                flex={0}
                // width="100%"
                transform="translate(-75px, 0)"
              >
                <Img fluid={props.data.unwind.childImageSharp.fluid} />
              </Box>
              {/* {(hasBeenOnScreen || onScreen) && <ProcessIcon width={"200px"} />} */}
            </Grid>
            <Box flex="1">
              <SectionTitle textAlign="left" variant="script">
                {homepage.data.process_title}
              </SectionTitle>
              <Box fontSize="md">
                <RichText
                  render={homepage.data.process_content.raw}
                  linkResolver={linkResolver}
                  htmlSerializer={sectionSerializer}
                />
              </Box>

              <Link fontWeight="bold" mt={10} href="/om-kbt" fontSize="sm">
                Läs mer om KBT
              </Link>
            </Box>
          </Flex>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper flex="1">
          <Flex>
            <Box flex="1">
              <SectionTitle variant="script">Exempel på områden</SectionTitle>

              <List spacing={3}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  Stress, utmattning och sömnproblem
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  Oro och ångest
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  Låg självkänsla
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  Relationsproblem
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  Kris och sorg
                </ListItem>
                {/* You can also use custom icons from react-icons */}
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  Personlig utveckling
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  Föräldrarskap (även familjehem)
                </ListItem>
              </List>

              {/* <Grid
                gridTemplateColumns={[
                  "repeat(auto-fill, minmax(150px, 1fr))",
                  "repeat(auto-fill, minmax(150px, 1fr))",
                  "repeat(auto-fill, minmax(250px, 1fr))",
                ]}
                gridGap="10px"
              >
                {homepage.data.areas_of_expertise.map((area, i) => {
                  return (
                    <Text
                      key={`area-${i}`}
                      // bg="gray.100"
                      borderRadius="4px"
                      p="16px"
                      fontWeight="bold"
                      fontSize="xs"
                      textAlign="center"
                      as="span"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {upperFirst(area.area_label.trim())}
                    </Text>
                  );
                })}
              </Grid> */}
            </Box>
            <Grid
              width="100%"
              // alignItems="center"
              // justifyContent="center"
              flex="1"
              display={["none", "none", "none", "grid"]}
              ref={iconRef}
            >
              <Box
                p="20px"
                ml={"10px"}
                display="block"
                flex={0}
                width="250px"
                // width="100%"
                transform="translate(50px, -30px)"
              >
                <Img
                  maxH="100px"
                  fluid={props.data.sad.childImageSharp.fluid}
                />
                {/*  */}
              </Box>

              {/* {(hasBeenOnScreen || onScreen) && <ProcessIcon width={"200px"} />} */}
            </Grid>
          </Flex>
        </Wrapper>
      </Section>
      <WaveBottom />
      <About {...props} />

      {/* <Wrapper as="main" id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Title style={{ marginTop: '8rem' }}>Recent projects</Title>
          <ProjectListing>
            {projects.nodes.map((project) => (
              <li key={project.primary.label.text}>
                <a href={project.primary.link.url}>{project.primary.label.text}</a>
              </li>
            ))}
          </ProjectListing>
        </Wrapper> */}
    </Layout>
  );
};

export default withPreview(IndexPage);

IndexPage.propTypes = {
  data: PropTypes.shape({
    prismicHomepage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
      }),
    }),
    projects: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    portrait: file(relativePath: { eq: "kerstin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    unwind: file(relativePath: { eq: "illustrationer/head-unwind.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    sad: file(relativePath: { eq: "illustrationer/sad.png" }) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    illustration1: file(
      relativePath: { eq: "illustrationer/photo_2021-09-14 14.34.19.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    illustration2: file(
      relativePath: { eq: "illustrationer/photo_2021-09-14 14.34.22.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    prismicHomepage {
      data {
        title {
          text
        }
        content {
          raw
        }
        areas_of_expertise {
          area_label
        }

        about_title

        about_me_content {
          raw
        }

        process_title

        process_content {
          raw
        }

        footer {
          raw
        }
      }
    }
  }
`;
